import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/tsuto/webapp/pcrecommend/src/components/layout.js";
import Image from "components/image";
import TOC from "components/toc";
import SaleLoadable from "components/saleLoadable";
import Socials from "components/socials";
import SmallBox from "components/smallBox";
import AccentBox from "components/accentBox";
import BasicTable from "components/basicTable";
import { Link, graphql } from "gatsby";
import Card from "components/card";
import PassMarkSearch from 'components/passMarkSearch';
import PassMark from 'components/cpu/passmark';
export const query = graphql`
  {
    allMdx(filter: {slug: {eq: "used-shop"}}) {
      nodes {
        headings {
          depth
          value
        }
      }
    },
    cover: file(relativePath: { eq: "cover/rank/usedshop.png"}) { ...eyecatchImg },
    ccpuhikaku: file(relativePath: { eq: "cover/cpu/cpu-hikaku.png"}) { ...eyecatchImg },
    cgrabohikaku: file(relativePath: { eq: "cover/gpu/grabo-hikaku.png"}) { ...eyecatchImg },
    digital_dragon: file(relativePath: { eq: "where/digital_dragon.png"}) { ...normalImg },
    amazon: file(relativePath: { eq: "where/amazon.png"}) { ...normalImg },
    rycycleshop: file(relativePath: { eq: "where/rycycleshop.png"}) { ...normalImg },
    freemarket: file(relativePath: { eq: "where/freemarket.png"}) { ...normalImg },
    junk_world: file(relativePath: { eq: "where/junk_world.png"}) { ...normalImg },
    pcnext: file(relativePath: { eq: "where/pcnext.png"}) { ...normalImg },
    qualit: file(relativePath: { eq: "where/qualit.png"}) { ...normalImg },
    kujiraya: file(relativePath: { eq: "where/kujiraya.png"}) { ...normalImg },
    pc_ichiba: file(relativePath: { eq: "where/pc_ichiba.png"}) { ...normalImg },
    bestock: file(relativePath: { eq: "where/bestock.png"}) { ...normalImg },
    iosys: file(relativePath: { eq: "where/iosys.png"}) { ...normalImg },
    pcwrap: file(relativePath: { eq: "where/pcwrap.png"}) { ...normalImg },
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`中古パソコンおすすめショップランキング【９選】`}</h1>
    <Socials {...props} mdxType="Socials" />
    <Image {...props} name="cover" alt="中古パソコンおすすめショップランキング" mdxType="Image" />
    <p>{`中古パソコンで品質と価格のバランスが取れた選択肢を考えると、セキュリティやオペレーションに独自のノウハウを持つ事が多い中古パソコンショップに行きつく。`}</p>
    <p>{`ただ、ひとことに中古パソコンショップと言っても、その規模やビジネスモデルは様々であり、どの店で購入すると満足度の高い買い物ができるのかを判断するのは難しい。`}</p>
    <p>{`そこで本ページでは数ある中古パソコンショップの中で、特に品質と価格に優れるショップ、あるいは比較のため一部初心者向けショップも紹介し、満足のいくパソコンを購入できることを目指す。`}</p>
    <TOC {...props} mdxType="TOC" />
    <h2 {...{
      "id": "中古パソコン選びのポイント",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E4%B8%AD%E5%8F%A4%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E9%81%B8%E3%81%B3%E3%81%AE%E3%83%9D%E3%82%A4%E3%83%B3%E3%83%88",
        "aria-label": "中古パソコン選びのポイント permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`中古パソコン選びのポイント`}</h2>
    <p>{`中古パソコンショップを紹介する前に簡単に選び方のポイントや便利ツールを紹介しておく。`}</p>
    <AccentBox title="中古パソコンの選び方まとめ" mdxType="AccentBox">
  <li><span className="bold">OS</span>・・・セキュリティパッチ配布等の観点からWindows 10をサポート期間(2025年10月14日まで)終了後３年程度は使っても問題はない。またWindows 11へのCPUの公式要件はIntelの第８世代以上、Ryzenの2000シリーズ以降でこれらは安全かつ無償でWindows 11にアップグレード可能。</li>
  <li><span className="bold">CPU</span>・・・CPUは世代やデスクトップ版やノート版などにより大きく性能が異なる。後述するがPassMarkベンチマークの結果とその目安で判断すべきである。</li>
  <li><span className="bold">バッテリー</span>・・・8年経過のパソコンで7割程度バッテリー残量が一般的。使用年数と見た目の使用感などで判断するしかないが、ショップによってはバッテリー残量を保証してくれている。</li>
  <li><span className="bold">マイクロソフトオフィス</span>・・・中古パソコンの故障リスクを考えると、本体が壊れると権利を失うプリインストール版(OEM)版は割に合わず、おすすめできない。必要ならばやや初期費用は高くなるが、<a href="https://amzn.to/3X7fiob" target="_blank" rel="nofollow noopenner">オンラインコード版</a>を購入し、買い替えた先のパソコンでも利用可能な状態にするのが得策。</li>
    </AccentBox>
    <p>{`より詳しくは`}<Link to="/used-select/" mdxType="Link">{`中古パソコンの選び方 おすすめのスペックや狙い目の機種`}</Link>{`という記事で紹介しているため、参考にしてほしい。`}</p>
    <p>{`またどのようなスペックのPCを選べばよいか分からない、スペックの見方が分からないという人は本サイトトップページの`}<Link to="/#パソコンの選び方を項目別にアドバイス" mdxType="Link">{`パソコンの選び方を項目別にアドバイス`}</Link>{`で確認してほしい。`}</p>
    <h3 {...{
      "id": "CPU性能を簡単にチェック",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#CPU%E6%80%A7%E8%83%BD%E3%82%92%E7%B0%A1%E5%8D%98%E3%81%AB%E3%83%81%E3%82%A7%E3%83%83%E3%82%AF",
        "aria-label": "CPU性能を簡単にチェック permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`CPU性能を簡単にチェック`}</h3>
    <p>{`CPUはパソコンの処理速度の根幹をなす重要なパーツであるが、型番を見ただけでは性能が分からないため、`}<strong parentName="p"><em parentName="strong">{`PassMarkというCPUの総合的な性能を指数化したベンチマークテストの結果を使うのがおすすめ`}</em></strong>{`である。`}</p>
    <p>{`スコアの目安とチェックツール(`}<em parentName="p">{`PASSMARK SEARCH`}</em>{`)を下に用意しているので、中古パソコンのスペックからCPUの型番情報を入力して性能をチェックして欲しい。2000点以下のカスなCPU搭載の中古パソコンは選ばないように気を付けたい。`}</p>
    <PassMark mdxType="PassMark" />
    <PassMarkSearch mdxType="PassMarkSearch" />
    <p>{`より詳しくは下記のCPU比較表でも確認できる。`}</p>
    <Card {...props} title="CPU性能比較表" description="400を超えるCPUから高機能フィルタにより世代間/ベンチマーク/TDP/コスパ等の絞りこみが行えます。" image="ccpuhikaku" path="/cpu/" mdxType="Card" />
    <h2 {...{
      "id": "中古パソコンのおすすめショップランキング",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E4%B8%AD%E5%8F%A4%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%81%AE%E3%81%8A%E3%81%99%E3%81%99%E3%82%81%E3%82%B7%E3%83%A7%E3%83%83%E3%83%97%E3%83%A9%E3%83%B3%E3%82%AD%E3%83%B3%E3%82%B0",
        "aria-label": "中古パソコンのおすすめショップランキング permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`中古パソコンのおすすめショップランキング`}</h2>
    <p>{`それでは中古パソコンのおすすめショップランキングを紹介する。`}</p>
    <p>{`店舗で大規模に展開しているソフマップなど量販店販売の中古品はコストパフォーマンスが極めて低いので除き、それ以外で十分な取引量を備えているショップを紹介する。`}</p>
    <p>{`特に`}<strong parentName="p"><em parentName="strong">{`ランキング上位２社は価格、品質、保証面で極めて高い水準を有している`}</em></strong>{`ため中古パソコンを購入するならばまず確認しておきたいショップである。`}<strong parentName="p"><em parentName="strong">{`３位は品質と保証は並であるが、価格と品揃えに秀でる`}</em></strong>{`ためチェックこちらもチェックしておきたい。`}</p>
    <p>{`続いて`}<strong parentName="p"><em parentName="strong">{`４～５位はコスパが大きく優れるわけではないが、中古パソコンに対して不安がある人におすすめしたい`}</em></strong>{`。これらで見つからない場合は６～８位も見てみると良いだろう。`}</p>
    <p>{`９位は特におすすめしたいわけではないが初心者向けであり、他と毛色が異なるという意味で紹介している。それでは１位から紹介する。`}</p>
    <h3 {...{
      "id": "１位：Qualit---ビジネスクオリティの中古パソコン",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%EF%BC%91%E4%BD%8D%EF%BC%9AQualit---%E3%83%93%E3%82%B8%E3%83%8D%E3%82%B9%E3%82%AF%E3%82%AA%E3%83%AA%E3%83%86%E3%82%A3%E3%81%AE%E4%B8%AD%E5%8F%A4%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3",
        "aria-label": "１位：Qualit   ビジネスクオリティの中古パソコン permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`１位：Qualit - ビジネスクオリティの中古パソコン`}</h3>
    <Image {...props} name="qualit" alt="Qualit" mdxType="Image" />
    <BasicTable className={'center top-margin'} mdxType="BasicTable">
  <tbody>
    <tr>
      <th>コスパ</th>
      <th>品質</th>
      <th>品揃え</th>
      <th>送料</th>
      <th>保証</th>
    </tr>
    <tr>
      <td>S</td>
      <td>S</td>
      <td>B</td>
      <td>１万円以上無料</td>
      <td>12ヵ月</td>
    </tr>
  </tbody>
    </BasicTable>
    <p>{`まず最もおすすめしたいサイトがこのQualitである。他の中古パソコンと比べ圧倒的なコストパフォーマンスと品質を誇る。`}</p>
    <p>{`横河レンタ・リースという法人向けとして有名なパソコンのレンタルサービスがあり、私が勤めている会社でも利用しているのだが、`}<strong parentName="p"><em parentName="strong">{`レンタル期限を終えて返却されたパソコンを再整備して売りに出している`}</em></strong>{`のである。`}</p>
    <p>{`安値で仕入れ、高値で売るという多くの中古パソコンショップとは`}<strong parentName="p"><em parentName="strong">{`ビジネススキームで一線を画しており、これが圧倒的な価格競争力を実現`}</em></strong>{`している。`}</p>
    <p>{`更に会社として法人向けの厳しいビジネス要求に応えられるよう`}<strong parentName="p"><em parentName="strong">{`バッテリーチェックを含め高いレベルの点検・整備を行なっている`}</em></strong>{`ので品質面で非の打ちどころは無い。`}</p>
    <p>{`法人用にレンタルで使われているという事実は、個人で無理な使われ方をしたパソコンが来ないという点でも安心材料である。`}</p>
    <SmallBox type="word accent" text="公式サイトへ" mdxType="SmallBox">
  <a href="https://px.a8.net/svt/ejp?a8mat=3NPOM7+F0LP5E+50H8+5YJRM" target="_blank" rel="nofollow noopener">Qualit</a>
  <img border="0" width="1" height="1" src="https://www12.a8.net/0.gif?a8mat=3NPOM7+F0LP5E+50H8+5YJRM" alt="" />
    </SmallBox>
    <h3 {...{
      "id": "２位：PC-WRAP---業界最長３年保証を誇る中古PCの老舗",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%EF%BC%92%E4%BD%8D%EF%BC%9APC-WRAP---%E6%A5%AD%E7%95%8C%E6%9C%80%E9%95%B7%EF%BC%93%E5%B9%B4%E4%BF%9D%E8%A8%BC%E3%82%92%E8%AA%87%E3%82%8B%E4%B8%AD%E5%8F%A4PC%E3%81%AE%E8%80%81%E8%88%97",
        "aria-label": "２位：PC WRAP   業界最長３年保証を誇る中古PCの老舗 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`２位：PC WRAP - 業界最長３年保証を誇る中古PCの老舗`}</h3>
    <Image {...props} name="pcwrap" alt="PCWrap" mdxType="Image" />
    <BasicTable className={'center top-margin'} mdxType="BasicTable">
  <tbody>
    <tr>
      <th>コスパ</th>
      <th>品質</th>
      <th>品揃え</th>
      <th>送料</th>
      <th>保証</th>
    </tr>
    <tr>
      <td>A+</td>
      <td>A+</td>
      <td>A</td>
      <td>9999円以上無料</td>
      <td>3年</td>
    </tr>
  </tbody>
    </BasicTable>
    <p>{`東証一部上場の急成長IT企業であるShiftのグループ会社である株式会社エスエヌシーが運営するパソコンショップで、大阪を主な拠点とする。`}</p>
    <p>{`中古PC販売20年以上の老舗であり、年間６万台程度パソコンを再生するトップクラスの取引量を誇る。`}</p>
    <p>{`取引量と効率的な検品のオペレーションからくる`}<strong parentName="p"><em parentName="strong">{`コストパフォーマンスに加え、３年保証、当日発送など購入者にとっての安心材料も豊富`}</em></strong>{`である。`}</p>
    <SmallBox type="word accent" text="公式サイトへ" mdxType="SmallBox">
      <a href="https://px.a8.net/svt/ejp?a8mat=3NPOM7+FVK8LU+4U56+5YRHE" target="_blank" rel="nofollow noopener">PC WRAP</a>
      <img border="0" width="1" height="1" src="https://www13.a8.net/0.gif?a8mat=3NPOM7+FVK8LU+4U56+5YRHE" alt="" />
    </SmallBox>
    <h3 {...{
      "id": "３位：JUNKWORLD---激安中古パソコンの殿堂",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%EF%BC%93%E4%BD%8D%EF%BC%9AJUNKWORLD---%E6%BF%80%E5%AE%89%E4%B8%AD%E5%8F%A4%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%81%AE%E6%AE%BF%E5%A0%82",
        "aria-label": "３位：JUNKWORLD   激安中古パソコンの殿堂 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`３位：JUNKWORLD - 激安中古パソコンの殿堂`}</h3>
    <Image {...props} name="junk_world" alt="JUNKWORLD" mdxType="Image" />
    <BasicTable className={'center top-margin'} mdxType="BasicTable">
  <tbody>
    <tr>
      <th>コスパ</th>
      <th>品質</th>
      <th>品揃え</th>
      <th>送料</th>
      <th>保証</th>
    </tr>
    <tr>
      <td>A+</td>
      <td>B</td>
      <td>S</td>
      <td>900円~</td>
      <td>1ヵ月</td>
    </tr>
  </tbody>
    </BasicTable>
    <p>{`株式会社アールキューブというパソコン回収とデータ消去を中心とした事業を行なっている会社が運営している中古パソコンショップ。`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.pc-kaishu.com/",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`パソコン回収.com`}</a>{`などを通じて、法人、個人双方から不要になったパソコンの回収を行なっており、`}<strong parentName="p"><em parentName="strong">{`個人はどんなパソコンであっても無料で引き取ってくれる`}</em></strong>{`。`}</p>
    <p>{`このサービス、あるいは買取を通じて集まったパソコンのうち再利用可能なものを再整備して発売しているサイトがJUNKWORLDである。`}</p>
    <p>{`他社が真似できないユニークな方法でパソコンを集めていおり、年間数万台の販売実績を誇り規模も大きい事から`}<strong parentName="p"><em parentName="strong">{`品揃えや価格競争力は十分に高い`}</em></strong>{`が、ショップ名の通りジャンク品が豊富で品質のバラつきには注意が必要なため、商品写真等には目を配りたい。`}</p>
    <SmallBox type="word accent" text="公式サイトへ" mdxType="SmallBox">
      <a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=888459649" target="_blank" rel="nofollow noopener"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=888459649" height="1" width="1" border="0" />JUNKWORLD</a>
    </SmallBox>
    <h3 {...{
      "id": "４位：パソコン市場---安くはないが初中級者に優しいパソコン",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%EF%BC%94%E4%BD%8D%EF%BC%9A%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E5%B8%82%E5%A0%B4---%E5%AE%89%E3%81%8F%E3%81%AF%E3%81%AA%E3%81%84%E3%81%8C%E5%88%9D%E4%B8%AD%E7%B4%9A%E8%80%85%E3%81%AB%E5%84%AA%E3%81%97%E3%81%84%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3",
        "aria-label": "４位：パソコン市場   安くはないが初中級者に優しいパソコン permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`４位：パソコン市場 - 安くはないが初中級者に優しいパソコン`}</h3>
    <Image {...props} name="pc_ichiba" alt="パソコン市場" mdxType="Image" />
    <BasicTable className={'center top-margin'} mdxType="BasicTable">
  <tbody>
    <tr>
      <th>コスパ</th>
      <th>品質</th>
      <th>品揃え</th>
      <th>送料</th>
      <th>保証</th>
    </tr>
    <tr>
      <td>B</td>
      <td>B+</td>
      <td>A</td>
      <td>1430円~</td>
      <td>6ヵ月</td>
    </tr>
  </tbody>
    </BasicTable>
    <p>{`中古パソコンショップでは老舗で、`}<strong parentName="p"><em parentName="strong">{`マイクロソフトMAR（Microsoft Authorized Refurbisher）プログラムに認定された再生PC事業者`}</em></strong>{`。`}</p>
    <p>{`パソコン自体のコストパフォーマンスはそれほど高くはないが、送料のみの負担で３０日返品可能、土日サポート、東京都から鹿児島まで25店舗程度の実店舗運営などは`}<strong parentName="p"><em parentName="strong">{`パソコン初心者～中級者にとって非常に安心感が高い`}</em></strong>{`ポイントである。`}</p>
    <p>{`正規のマイクロソフトオフィスがインストールされたパソコンを手軽に使いたいという人や、あまりパソコンに自信はないがコストは抑えたい人にとって良い選択肢となるはずである。`}</p>
    <SmallBox type="word accent" text="公式サイトへ" mdxType="SmallBox">
      <a href="https://px.a8.net/svt/ejp?a8mat=3T0ILT+7X6QMQ+4TG6+5YRHE" target="_blank" rel="nofollow noopener">パソコン市場</a>
      <img border="0" width="1" height="1" src="https://www19.a8.net/0.gif?a8mat=3T0ILT+7X6QMQ+4TG6+5YRHE" alt="" />
    </SmallBox>
    <h3 {...{
      "id": "５位：PC-next---型落ちの日系パソコンを買うなら狙い目",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%EF%BC%95%E4%BD%8D%EF%BC%9APC-next---%E5%9E%8B%E8%90%BD%E3%81%A1%E3%81%AE%E6%97%A5%E7%B3%BB%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%82%92%E8%B2%B7%E3%81%86%E3%81%AA%E3%82%89%E7%8B%99%E3%81%84%E7%9B%AE",
        "aria-label": "５位：PC next   型落ちの日系パソコンを買うなら狙い目 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`５位：PC next - 型落ちの日系パソコンを買うなら狙い目`}</h3>
    <Image {...props} name="pcnext" alt="PC next" mdxType="Image" />
    <BasicTable className={'center top-margin'} mdxType="BasicTable">
  <tbody>
    <tr>
      <th>コスパ</th>
      <th>品質</th>
      <th>品揃え</th>
      <th>送料</th>
      <th>保証</th>
    </tr>
    <tr>
      <td>B</td>
      <td>S</td>
      <td>B+</td>
      <td>無料</td>
      <td>12ヵ月</td>
    </tr>
  </tbody>
    </BasicTable>
    <p>{`コンプライアンスが厳しい関西電力の子会社が運営する中古パソコンショップ。故障率が高いHDDを新品のSSDに交換して販売する、１年保証、販売商品に一定のスペック基準を設けるなど`}<strong parentName="p"><em parentName="strong">{`使用者が安心して中古パソコンを使えることに注力している`}</em></strong>{`ことが特徴である。`}</p>
    <p>{`ただ、当然価格は高くなるわけであり、正直DellやLenovoのPCをここで購入するぐらいならば新品を購入することをおすすめしたい。`}</p>
    <p>{`しかしながらNEC、Dynabook、Vaio、Let's Noteなど`}<strong parentName="p"><em parentName="strong">{`日系ブランドの製品は正規価格と比べ割引率が高く、しっかり整備されていることを考えると狙い目`}</em></strong>{`である。`}</p>
    <SmallBox type="word accent" text="公式サイトへ" mdxType="SmallBox">
      <a href="https://px.a8.net/svt/ejp?a8mat=3Z4ON3+G1IKNM+4X3U+5YRHE" target="_blank" rel="nofollow noopener">PC next</a>
      <img border="0" width="1" height="1" src="https://www14.a8.net/0.gif?a8mat=3Z4ON3+G1IKNM+4X3U+5YRHE" alt="" />
    </SmallBox>
    <h3 {...{
      "id": "６位：イオシス---Macを中心に新し目のパソコンが豊富",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%EF%BC%96%E4%BD%8D%EF%BC%9A%E3%82%A4%E3%82%AA%E3%82%B7%E3%82%B9---Mac%E3%82%92%E4%B8%AD%E5%BF%83%E3%81%AB%E6%96%B0%E3%81%97%E7%9B%AE%E3%81%AE%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%81%8C%E8%B1%8A%E5%AF%8C",
        "aria-label": "６位：イオシス   Macを中心に新し目のパソコンが豊富 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`６位：イオシス - Macを中心に新し目のパソコンが豊富`}</h3>
    <Image {...props} name="iosys" alt="イオシス" mdxType="Image" />
    <BasicTable className={'center top-margin'} mdxType="BasicTable">
  <tbody>
    <tr>
      <th>コスパ</th>
      <th>品質</th>
      <th>品揃え</th>
      <th>送料</th>
      <th>保証</th>
    </tr>
    <tr>
      <td>A</td>
      <td>B+</td>
      <td>A</td>
      <td>970円~</td>
      <td>3ヵ月</td>
    </tr>
  </tbody>
    </BasicTable>
    <p>{`秋葉原に多くの店舗を構える、スマホの中古販売は国内首位級で超有名なショップであるがパソコンの中古販売も行っている。`}</p>
    <p>{`特に`}<strong parentName="p"><em parentName="strong">{`MacBookに関しては非常に商品を多く揃えており`}</em></strong>{`、個人からの買取を中心に行っているため新しい商品も多い。iPhoneとMacならイオシスというイメージが強い。`}</p>
    <p>{`値段は結構安いという宣伝のままであり、激安でも無ければ高いとも思わない。Macbookは値崩れしにくい商品で中古価格はどこも似たりよったりであるが、このショップは取り扱い数が多いので`}<strong parentName="p"><em parentName="strong">{`希望のものを探すことができるのが一番のメリット`}</em></strong>{`である。`}</p>
    <p>{`MAC以外についても、秋葉原を中心に店舗を構えることから`}<strong parentName="p"><em parentName="strong">{`新しいパソコンを多く入荷していることが特長`}</em></strong>{`である。`}</p>
    <SmallBox type="word accent" text="公式サイトへ" mdxType="SmallBox">
      <a href="https://px.a8.net/svt/ejp?a8mat=3NPNUK+61WR9U+ZFU+66OZ6" target="_blank" rel="nofollow noopener">イオシス</a>
      <img border="0" width="1" height="1" src="https://www14.a8.net/0.gif?a8mat=3NPNUK+61WR9U+ZFU+66OZ6" alt="" />
    </SmallBox>
    <h3 {...{
      "id": "７位：Be-Stock---Think-Padに特化した専門点",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%EF%BC%97%E4%BD%8D%EF%BC%9ABe-Stock---Think-Pad%E3%81%AB%E7%89%B9%E5%8C%96%E3%81%97%E3%81%9F%E5%B0%82%E9%96%80%E7%82%B9",
        "aria-label": "７位：Be Stock   Think Padに特化した専門点 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`７位：Be-Stock - Think Padに特化した専門点`}</h3>
    <Image {...props} name="bestock" alt="Be-Stock" mdxType="Image" />
    <BasicTable className={'center top-margin'} mdxType="BasicTable">
  <tbody>
    <tr>
      <th>コスパ</th>
      <th>品質</th>
      <th>品揃え</th>
      <th>送料</th>
      <th>保証</th>
    </tr>
    <tr>
      <td>A</td>
      <td>A</td>
      <td>B</td>
      <td>1100円~</td>
      <td>12ヵ月</td>
    </tr>
  </tbody>
    </BasicTable>
    <p>{`アイ・ティー・エス・ジャパン株式会社という社員数100名未満のシステムインテグレーションの会社が運営するパソコンショップ。倉庫は鹿児島にあり、そこから全国に発送している。`}</p>
    <p>{`多様なメーカーのパソコンを取り扱っているが、特に`}<strong parentName="p"><em parentName="strong">{`Thinkpadについては独自の調達ルートを持つ模様であり、品質・価格とも評価が高い`}</em></strong>{`。`}</p>
    <p>{`実店舗としてはThinkPad専門の１号店と、その他のメーカーを扱う２号店で分かれており、それに伴い、WebサイトにもShop1とShop2の２つが存在する。`}</p>
    <p>{`仕入れにはやや偏りがあるものの、`}<strong parentName="p"><em parentName="strong">{`コストパフォーマンスは高く、電池残量80%以上のものは表記があるため安心して購入することが可能`}</em></strong>{`である。`}</p>
    <SmallBox type="word accent" text="公式サイトへ" mdxType="SmallBox">
      <a href="https://px.a8.net/svt/ejp?a8mat=3T28HF+B9GATU+3JD0+5ZEMQ" target="_blank" rel="nofollow noopener">Be-Stock(ThinkPad専門店)</a>
      <img border="0" width="1" height="1" src="https://www13.a8.net/0.gif?a8mat=3T28HF+B9GATU+3JD0+5ZEMQ" alt="" />　/　
      <a href="https://px.a8.net/svt/ejp?a8mat=3T4O7K+CONKUQ+3JD0+C03K2" target="_blank" rel="nofollow noopener">Be-Stock(その他のメーカー)</a>
      <img border="0" width="1" height="1" src="https://www14.a8.net/0.gif?a8mat=3T4O7K+CONKUQ+3JD0+C03K2" alt="" />
    </SmallBox>
    <h3 {...{
      "id": "８位：デジタルドラゴン---最新のコンシューマ系中古が充実",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%EF%BC%98%E4%BD%8D%EF%BC%9A%E3%83%87%E3%82%B8%E3%82%BF%E3%83%AB%E3%83%89%E3%83%A9%E3%82%B4%E3%83%B3---%E6%9C%80%E6%96%B0%E3%81%AE%E3%82%B3%E3%83%B3%E3%82%B7%E3%83%A5%E3%83%BC%E3%83%9E%E7%B3%BB%E4%B8%AD%E5%8F%A4%E3%81%8C%E5%85%85%E5%AE%9F",
        "aria-label": "８位：デジタルドラゴン   最新のコンシューマ系中古が充実 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`８位：デジタルドラゴン - 最新のコンシューマ系中古が充実`}</h3>
    <Image {...props} name="digital_dragon" alt="デジタルドラゴン" mdxType="Image" />
    <BasicTable className={'center top-margin'} mdxType="BasicTable">
  <tbody>
    <tr>
      <th>コスパ</th>
      <th>品質</th>
      <th>品揃え</th>
      <th>送料</th>
      <th>保証</th>
    </tr>
    <tr>
      <td>B</td>
      <td>B+</td>
      <td>A</td>
      <td>2200円~</td>
      <td>3ヵ月</td>
    </tr>
  </tbody>
    </BasicTable>
    <p>{`デジタルドランゴンはパソコン工房傘下の中古パソコンブランドである。買取は実店舗を通じて行なっているため、安く買って高く買うモデルであるが、他と比べると`}<strong parentName="p"><em parentName="strong">{`中古査定には１時間以上の時間を要し十分な精査が行なわれる`}</em></strong>{`。`}</p>
    <p>{`品質がシビアな分、価格は激安とまではいかないが、パソコン工房の新品製品と同様コスパは十分に高く、リーゾナブルである。また`}<strong parentName="p"><em parentName="strong">{`品揃えがノートパソコンからゲーミングPC、自作PCまで幅広く、比較的新しいパソコンが多い`}</em></strong>{`。`}</p>
    <p>{`パソコン工房の新品パソコンと同様、デジタルドラゴンは品質と価格がそれなりに高いレベルで安定している。`}</p>
    <SmallBox type="word accent" text="公式サイトへ" mdxType="SmallBox">
      <a href="https://click.linksynergy.com/deeplink?id=xsv0FDnJs1M&mid=13526&murl=https%3A%2F%2Fwww.pc-koubou.jp%2Fpc%2Fused.php" target="_blank" rel="nofollow noopener">デジタルドラゴン</a>
      <img alt="icon" width="1" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=83593.1&type=10" />
    </SmallBox>
    <h3 {...{
      "id": "９位：くじらや---スペックは良く分からないが動かしたい人向け",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%EF%BC%99%E4%BD%8D%EF%BC%9A%E3%81%8F%E3%81%98%E3%82%89%E3%82%84---%E3%82%B9%E3%83%9A%E3%83%83%E3%82%AF%E3%81%AF%E8%89%AF%E3%81%8F%E5%88%86%E3%81%8B%E3%82%89%E3%81%AA%E3%81%84%E3%81%8C%E5%8B%95%E3%81%8B%E3%81%97%E3%81%9F%E3%81%84%E4%BA%BA%E5%90%91%E3%81%91",
        "aria-label": "９位：くじらや   スペックは良く分からないが動かしたい人向け permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`９位：くじらや - スペックは良く分からないが動かしたい人向け`}</h3>
    <Image {...props} name="kujiraya" alt="くじらや" mdxType="Image" />
    <BasicTable className={'center top-margin'} mdxType="BasicTable">
  <tbody>
    <tr>
      <th>コスパ</th>
      <th>品質</th>
      <th>品揃え</th>
      <th>送料</th>
      <th>保証</th>
    </tr>
    <tr>
      <td>C</td>
      <td>B</td>
      <td>B</td>
      <td>無料</td>
      <td>1ヵ月</td>
    </tr>
  </tbody>
    </BasicTable>
    <p>{`パソコンに詳しい友人が出来たような安心感を皆様に与えてくれるが、`}<strong parentName="p"><em parentName="strong">{`決してパソコンに詳しい友人なら薦めないショップ`}</em></strong></p>
    <p>{`安物のグラフィックボードを搭載したものをゲーミングパソコンとし称して出品している様や10年前のパソコンを堂々と爆速と言い放つ様は潔さを感じる。`}</p>
    <p>{`更に細かく見てもCPUの型番が書いていない商品も多い。Core i3とだけ書いてあるがいつのCore i3か分からないので`}<strong parentName="p"><em parentName="strong">{`性能の判定も不能`}</em></strong>{`である。`}</p>
    <p>{`７日間返品OKだが、これは単にクーリングオフ制度で法律である、更には中古パソコンだからか`}<strong parentName="p"><em parentName="strong">{`SDGsを宣伝しているなど何とも言い難いサイト`}</em></strong>{`である。ちなみにコスパも悪い。`}</p>
    <p>{`ただサイトにも書かれている通り`}<strong parentName="p"><em parentName="strong">{`サポートだけは懇切丁寧`}</em></strong>{`であるため、予算もパソコンを使用する自信もない人がお試しで使ってみる分には、手軽さもありおすすめできるかもしれない。`}</p>
    <SmallBox type="word accent" text="公式サイトへ" mdxType="SmallBox">
      <a href="https://px.a8.net/svt/ejp?a8mat=3NPOM7+EWFNWY+52XK+5YJRM" target="_blank" rel="nofollow noopener">くじらや
      </a><img border="0" width="1" height="1" src="https://www15.a8.net/0.gif?a8mat=3NPOM7+EWFNWY+52XK+5YJRM" alt="" />
    </SmallBox>
    <h2 {...{
      "id": "【比較用】おすすめ中古パソコンショップ一覧表",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%80%90%E6%AF%94%E8%BC%83%E7%94%A8%E3%80%91%E3%81%8A%E3%81%99%E3%81%99%E3%82%81%E4%B8%AD%E5%8F%A4%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%82%B7%E3%83%A7%E3%83%83%E3%83%97%E4%B8%80%E8%A6%A7%E8%A1%A8",
        "aria-label": "【比較用】おすすめ中古パソコンショップ一覧表 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`【比較用】おすすめ中古パソコンショップ一覧表`}</h2>
    <p>{`最後に上記で紹介した中古パソコンショップを比較・検討しやすいよう一覧表としてまとめておく。`}</p>
    <p>{`コスパはスペックに対する価格、品質は製品の外れのなさ、保証期間等を考慮してランク付けを行なっている。複数ショップ間での製品比較で参考にしてほしい。`}</p>
    <BasicTable mdxType="BasicTable">
  <thead>
    <tr>
      <th>ショップ名</th>
      <th>コスパ</th>
      <th>品質</th>
      <th>品揃え</th>
      <th>送料</th>
      <th>保証</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><a href="https://px.a8.net/svt/ejp?a8mat=3NPOM7+F0LP5E+50H8+5YJRM" target="_blank" rel="nofollow noopener">Qualit</a>
  <img border="0" width="1" height="1" src="https://www12.a8.net/0.gif?a8mat=3NPOM7+F0LP5E+50H8+5YJRM" alt="" /></td>
      <td>S</td>
      <td>S</td>
      <td>B</td>
      <td>1万円以上無料</td>
      <td>12ヵ月</td>
    </tr>
    <tr>
      <td><a href="https://px.a8.net/svt/ejp?a8mat=3NPOM7+FVK8LU+4U56+5YRHE" target="_blank" rel="nofollow noopener">PC WRAP</a>
            <img border="0" width="1" height="1" src="https://www13.a8.net/0.gif?a8mat=3NPOM7+FVK8LU+4U56+5YRHE" alt="" /></td>
      <td>A+</td>
      <td>A+</td>
      <td>A</td>
      <td>9999円以上無料</td>
      <td>3年</td>
    </tr>
    <tr>
      <td><a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=888459649" target="_blank" rel="nofollow noopener"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=888459649" height="1" width="1" border="0" />JUNKWORLD</a></td>
      <td>A+</td>
      <td>B</td>
      <td>S</td>
      <td>900円~</td>
      <td>1ヵ月</td>
    </tr>
    <tr>
      <td><a href="https://px.a8.net/svt/ejp?a8mat=3T0ILT+7X6QMQ+4TG6+5YRHE" target="_blank" rel="nofollow noopener">パソコン市場</a>
            <img border="0" width="1" height="1" src="https://www19.a8.net/0.gif?a8mat=3T0ILT+7X6QMQ+4TG6+5YRHE" alt="" /></td>
      <td>B</td>
      <td>B+</td>
      <td>A</td>
      <td>1430円~</td>
      <td>6ヵ月</td>
    </tr>
    <tr>
      <td><a href="https://px.a8.net/svt/ejp?a8mat=3Z4ON3+G1IKNM+4X3U+5YRHE" target="_blank" rel="nofollow noopener">PC next</a>
            <img border="0" width="1" height="1" src="https://www14.a8.net/0.gif?a8mat=3Z4ON3+G1IKNM+4X3U+5YRHE" alt="" /></td>
      <td>B</td>
      <td>S</td>
      <td>B+</td>
      <td>無料</td>
      <td>12ヵ月</td>
    </tr>
    <tr>
      <td><a href="https://px.a8.net/svt/ejp?a8mat=3NPNUK+61WR9U+ZFU+66OZ6" target="_blank" rel="nofollow noopener">イオシス</a>
            <img border="0" width="1" height="1" src="https://www14.a8.net/0.gif?a8mat=3NPNUK+61WR9U+ZFU+66OZ6" alt="" /></td>
      <td>A</td>
      <td>B+</td>
      <td>A</td>
      <td>970円~</td>
      <td>3ヵ月</td>
    </tr>
    <tr>
      <td>Be Stock<br /><a href="https://px.a8.net/svt/ejp?a8mat=3T28HF+B9GATU+3JD0+5ZEMQ" target="_blank" rel="nofollow noopener">ThinkPad店</a>
            <img border="0" width="1" height="1" src="https://www13.a8.net/0.gif?a8mat=3T28HF+B9GATU+3JD0+5ZEMQ" alt="" />/<br /><a href="https://px.a8.net/svt/ejp?a8mat=3T4O7K+CONKUQ+3JD0+C03K2" target="_blank" rel="nofollow noopener">その他のメーカー</a>
            <img border="0" width="1" height="1" src="https://www14.a8.net/0.gif?a8mat=3T4O7K+CONKUQ+3JD0+C03K2" alt="" /></td>
      <td>A</td>
      <td>A</td>
      <td>B</td>
      <td>1100円~</td>
      <td>12ヵ月</td>
    </tr>
    <tr>
      <td><a href="https://click.linksynergy.com/deeplink?id=xsv0FDnJs1M&mid=13526&murl=https%3A%2F%2Fwww.pc-koubou.jp%2Fpc%2Fused.php" target="_blank" rel="nofollow noopener">デジタルドラゴン</a>
            <img alt="icon" width="1" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=83593.1&type=10" /></td>
      <td>B</td>
      <td>B+</td>
      <td>A</td>
      <td>2200円~</td>
      <td>3ヵ月</td>
    </tr>
    <tr>
      <td><a href="https://px.a8.net/svt/ejp?a8mat=3NPOM7+EWFNWY+52XK+5YJRM" target="_blank" rel="nofollow noopener">くじらや
            </a><img border="0" width="1" height="1" src="https://www15.a8.net/0.gif?a8mat=3NPOM7+EWFNWY+52XK+5YJRM" alt="" /></td>
      <td>C</td>
      <td>B</td>
      <td>B</td>
      <td>無料</td>
      <td>1ヵ月</td>
    </tr>
  </tbody>
    </BasicTable>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      